<template>
  <document-control-rs
    source="rs/getControl"
    pin-name="controls"
  >
    <template #action>
      <common-errors-action />
    </template>
  </document-control-rs>
</template>
<script>
import DocumentControlRs from "@/components/shared/document-control.vue";
import CommonErrorsAction from "./common-errors-action.vue";
export default {
  components: {
    DocumentControlRs,
    CommonErrorsAction,
  },
};
</script>
